import { post ,postJson} from '../libs/api.request'

//获取恒泰星寓生活
export const getLiveNewsList = (params) => post('/api/utWebNews/getListGroupByType', params);

//根据id查看详情

export const getLiveDeatil = (params) => post('/api/utWebNews/getById', params);

//根据类型和当前id获取5条同类型数据 为你推荐内容
export const getOtherLiveNews = (params) => post('/api/utWebNews/getOtherList', params);