<template>
  <div class="live-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header">
        <!-- <div class="header-content">
          <img
            src="@/assets/images/live/life_banner_icon.png"
            alt
            width="99px"
          />
          <p class="title">新巢天诚生活</p>
        </div> -->
      </div>

      <div class="press">
        <div class="news" v-for="(item, index) in newsList" :key="index">
          <div class="new-list">
            <img :src="item.imageUrl" alt />
          </div>
          <dl class="new-content">
            <dd class="new-title">{{ item.title }}</dd>
            <dd class="time">{{ item.time }}</dd>
            <dd class="digest" v-html="item.content"></dd>
            <!-- <dd class="content-detail" v-html="item.content"></dd> -->
            <dd
              class="checkDetail"
              @click="checkDetail(item.url, item.source, item.id)"
            >
              查看详情>
            </dd>
          </dl>
        </div>
        <div class="dataPage">
          <Page
            :total="totalNum"
            show-elevator
            show-total
            :page-size="pageSize"
            :current="currentPage"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
          />
        </div>
      </div>
    </div>
    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getLiveNewsList } from "@/api/live.api";
import { getnewsList } from "@/api/newWebsite";
export default {
  name: "live",
  components: { headerNav, footerNav },
  metaInfo: {
    title:
      "新巢天诚-新闻中心 智慧通行|人脸识别终端|人行通道闸机|CAT.1智能门锁|LORA智能门锁|UNB联网锁|NB人脸智能门锁|WiFi无线物联网锁|人才公寓管理系统|智慧公寓管理系统",
    meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      liveNewsList: [],
      News: [],
      totalNum: 0,
      pageSize: 5,
      currentPage: 1,
      newsList: [],
    };
  },
  methods: {
    changePage(index) {
      this.currentPage = index;
      this.getnewsList();
    },
    changePageSize(index) {
      this.pageSize = index;
      this.getnewsList();
    },
    //查看星寓说news
    getLiveNewsList() {
      getLiveNewsList({
        pageIndex: this.currentPage,
        size: this.pageSize,
      })
        .then((res) => {
          if (res.success) {
            this.liveNewsList = res.data;
            this.News = this.liveNewsList.newsList.records;
            this.totalNum = this.liveNewsList.newsList.total;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$Message.error("网络错误,请稍后在试！");
        });
    },
    //   跳转详情
    checkDetail(url, source, id) {
      //   this.$store.commit("changeLivenewsType", "新寓说News");
      //   this.$router.push({
      //     name: "hotel-news-detail",
      //     query: { liveDetailId: id },
      //   });
      //   globalEvents.$emit("checkDetail", "查看详情"); //触发全局事件

      if (source == 1) {
        window.open(url);
      } else if (source == 0) {
        console.log("点击新闻id", id);
        this.$router.push({
          name: "news-detail",
          query: {
            id: id,
          },
        });
      }
    },
    //查看新闻列表

    getnewsList() {
      getnewsList({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.code == 0) {
            this.newsList = res.data.list;
            this.totalNum = res.data.pageTotal;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取数据失败,请稍后再试");
        });
    },
  },
  created() {
    // this.getLiveNewsList();
    // this.$store.commit("changeAppointment", true);
    // this.$store.commit("changeLocation", 230);

    this.getnewsList();
  },
};
</script>

<style scoped>
.live-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 40px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/new/press.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.title {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
}
.live_list {
  margin-top: 40px;
  width: 1200px;
  margin: auto;
}

.list-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
}
.live_introduce {
  height: 60px;
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  border: 1px solid #dcdcdc;
  margin-top: -5px;
  border-top: none;
}
.header-content {
  min-width: 160px;
  height: 160px;
  margin: auto;
  padding-top: 120px;
}
.bg_shadow {
  /* box-shadow: 0 0 2px 0; */
  cursor: pointer;
}
.newsTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333333;
  font-size: 16px;
}

.press {
  max-width: 1200px;
  margin: auto;
  overflow-x: hidden;
}

.news {
  text-align: left;
  padding-top: 15px;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
}
.new-list {
  width: 500px;
  height: 280px;
  padding-bottom: 30px;
}
.new-list > img {
  width: 500px;
  height: 250px;
}
.new-content {
  flex: 1;
  margin-left: 30px;
  margin-top: 40px;
}
.new-title {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}
.time {
  color: #333333;
  font-size: 16px;
  padding-bottom: 15px;
}
.digest {
  color: #333333;
  font-size: 16px;
  padding-bottom: 15px;
  height: 43px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 670px;
}
.content-detail {
  color: #666666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.checkDetail {
  color: #ea5413;
  font-size: 14px;
  padding-top: 15px;
  cursor: pointer;
}
.dataPage {
  margin-top: 30px;
}
.dataPage >>> .ivu-page-item-active {
  border-color: #ea5413;
}
.dataPage >>> .ivu-page-item-active a {
  color: #ea5413;
}
.dataPage >>> .ivu-page-item:hover {
  border-color: #ea5413;
  color: #ea5413;
}
.dataPage >>> .ivu-page-item a:hover {
  color: #ea5413 !important;
}

@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }

  .main-header {
    height: 166px;
  }
  .new-list {
    display: none;
  }
  .new-content {
    margin-top: 0px;
    margin-left: 0px;
  }
  .news {
    padding-top: 0px;
    margin-top: 0px;
    padding: 10px;
  }
  .new-title {
    font-size: 14px;
  }
  .time {
    font-size: 13px;
  }
  .digest {
    font-size: 14px;
  }
  .dataPage >>> .ivu-page-options {
    margin-left: 0px;
  }
}
</style>
